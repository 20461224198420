import { ChangeEvent, ReactElement, useEffect, useRef, useState } from 'react';
import { t } from 'i18next';
import {
  SendCustomTextButton,
  FromStyledDiv,
  EmailKeyWordSpanElement,
  EmailReplyContainer,
  EmailResponseTextAreaStyled,
  FromAndToAddressContainer,
  InputStyledElement,
  ToAndCcStyledDiv,
  ValueSpanElement,
  AttachmentCustomTextButton,
  EmailAttachmentIconImage,
  DeleteImg,
  EmailResponseTextAreaStyledDiv,
  EmailReplyActionButtonsContainer,
  EmailReplySendAndAttachmentButtonContainer,
  EmailReplyAttachmentGrid,
  EmailReplyAttachmentNameTypography,
  EmailReplyAttachmentHiddenInput,
  DeleteIconForEmailAttachment,
  ToStyledDiv,
  EmailRecipientsDetailsLoadingContainer,
} from './EmailReplyStyled';
import AttachmentIcon from '@assets/images/Attachment.svg';
import { IconButton } from '@mui/material';
import DeleteIcon from '@assets/images/Delete.svg';
import { produce } from 'immer';
import CloseIconForEmailAttachment from '@assets/images/CloseIconWhite.svg';
import { enqueueSnackbar } from 'notistack';
import SnackBarConfig from '@components/snackbar/SnackbarConfig';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateEmailIdForReplyForm,
  resetEmailReplyForm,
  updateEmailReplyAttachments,
  updateCcEmailAddress,
  updateEmailReplyBody,
  updateToEmailAddress,
  updateFromEmailAddress,
  setIsSendingReplyEmail,
} from '@modules/home/state/email/EmailSlice';
import { RootState } from 'src/store/Store';
import Loader from '@components/loaders/Loader';
import {
  getEmailRecipientsById,
  sendEmailReplyById,
} from 'src/services/service-handlers/private/email-handlers/EmailPrivateService';
import {
  EmailReplyTypes,
  MAX_FILE_SIZE_FOR_EMAIL_REPLY_ATTACHMENT,
} from '@constants/global-constants/constants';
import { getErrorMessage } from '@utils/ErrorUtils';
import {
  SendEmailReplyAllByIdParams,
  SendEmailReplyByIdParams,
} from 'src/services/service-handlers/private/email-handlers/EmailServiceMapper';
import {
  isProductionEnvironment,
  isValidEmail,
} from '@utils/miscellaneousUtils';

interface IEmailReplyForm {
  emailId: string;
  onDeleteEmailReply: () => void;
  onSendSuccess: () => void;
  emailReplyType: string;
}

interface IEmailReplyDetailsState {
  isEmailRecipientsDetailsLoading: boolean;
}
const initialEmailReplyDetailsState: IEmailReplyDetailsState = {
  isEmailRecipientsDetailsLoading: true,
};

const EmailReplyForm = (props: IEmailReplyForm): ReactElement => {
  const { emailId, onDeleteEmailReply, onSendSuccess, emailReplyType } = props;

  const [emailReplyDetailsState, setEmailReplyDetailsState] =
    useState<IEmailReplyDetailsState>(initialEmailReplyDetailsState);

  const { isEmailRecipientsDetailsLoading } = emailReplyDetailsState;

  const {
    emailIdForReplyForm,
    toEmailAddress,
    ccEmailAddress,
    emailReplyBody,
    emailReplyAttachments,
    fromEmailAddress,
  } = useSelector((state: RootState) => state.email);

  const dispatch = useDispatch();
  const fileInputRef = useRef<HTMLInputElement>(null);

  // API region starts

  const getEmailRecipients = async () => {
    if (emailId) {
      setEmailReplyDetailsState(
        produce(draft => {
          draft.isEmailRecipientsDetailsLoading = true;
        }),
      );
      try {
        const getEmailRecipientsByIdRes = await getEmailRecipientsById(
          emailId,
          {
            formType: emailReplyType,
          },
        );
        if (getEmailRecipientsByIdRes?.data?.from?.length > 0) {
          dispatch(updateFromEmailAddress(getEmailRecipientsByIdRes.data.from));
        }
        if (getEmailRecipientsByIdRes?.data?.to?.length > 0) {
          dispatch(updateToEmailAddress(getEmailRecipientsByIdRes.data.to));
        }
        if (getEmailRecipientsByIdRes?.data?.cc?.length > 0) {
          if (isProductionEnvironment()) {
            dispatch(
              updateCcEmailAddress(
                getEmailRecipientsByIdRes.data.cc.join(', '),
              ),
            );
          } else {
            dispatch(updateCcEmailAddress(''));
          }
        }
      } catch (errorResponse) {
        const errMessage = getErrorMessage(errorResponse);
        enqueueSnackbar(errMessage, SnackBarConfig.getError());
      } finally {
        setEmailReplyDetailsState(
          produce(draft => {
            draft.isEmailRecipientsDetailsLoading = false;
          }),
        );
      }
    }
  };

  const sendEmailReply = async (
    emailResponsePayload:
      | SendEmailReplyByIdParams
      | SendEmailReplyAllByIdParams,
  ) => {
    dispatch(setIsSendingReplyEmail(true));
    try {
      const sendEmailReplyResponse = await sendEmailReplyById(
        emailResponsePayload,
        emailReplyType,
      );

      enqueueSnackbar(
        sendEmailReplyResponse?.data?.message,
        SnackBarConfig.getSuccess(),
      );
      onSendSuccess();
      dispatch(resetEmailReplyForm());
    } catch (errorResponse) {
      const errMessage = getErrorMessage(errorResponse);
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
    } finally {
      dispatch(setIsSendingReplyEmail(false));
    }
  };

  // API region ends

  useEffect(() => {
    getEmailRecipients();
    if (emailId !== emailIdForReplyForm) {
      dispatch(updateEmailReplyBody(''));
      dispatch(updateEmailReplyAttachments([]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailReplyType]);

  const isFileValid = (file: File) => {
    if (
      file.size > MAX_FILE_SIZE_FOR_EMAIL_REPLY_ATTACHMENT.MAX_FILE_SIZE_ALLOWED
    ) {
      enqueueSnackbar(
        t('QUEUE_DETAILS.EMAIL_REPLY_FORM_MAX_FILE_SIZE_ERROR'),
        SnackBarConfig.getError(),
      );
      return false;
    } else if (
      emailReplyAttachments.some(
        attachedFile => attachedFile.name === file.name,
      )
    ) {
      enqueueSnackbar(
        t('QUEUE_DETAILS.EMAIL_REPLY_FORM_DUPLICATE_FILE_ERROR'),
        SnackBarConfig.getError(),
      );
      return false;
    }
    return true;
  };

  const handleAddAttachmentRefClick = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const fileInput = event.target;
    const file = fileInput.files?.[0];
    if (file && isFileValid(file)) {
      if (emailId !== emailIdForReplyForm) {
        dispatch(updateEmailIdForReplyForm(emailId));
      }
      dispatch(updateEmailReplyAttachments([...emailReplyAttachments, file]));
    }
    fileInput.value = '';
  };

  const handleAddAttachmentButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current?.click();
    }
  };

  const resetFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleDeleteAttachment = (index: number) => {
    const updatedAttachments = emailReplyAttachments.filter(
      (_, i) => i !== index,
    );
    dispatch(updateEmailReplyAttachments(updatedAttachments));
    resetFileInput();
  };

  const handleToEmailAddressChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    dispatch(updateToEmailAddress(value));
  };

  const handleCcEmailAddressChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    dispatch(updateCcEmailAddress(value));
  };

  const handleEmailReplyBodyChange = (
    event: ChangeEvent<HTMLTextAreaElement>,
  ) => {
    if (emailId !== emailIdForReplyForm) {
      dispatch(updateEmailIdForReplyForm(emailId));
    }
    dispatch(updateEmailReplyBody(event.target.value));
  };

  const isRecipientEmailsValid = (emailAddresses: string): boolean => {
    return emailAddresses
      .split(',')
      .map(element => element.trim())
      .every(isValidEmail);
  };

  const validateEmailReplyForm = () => {
    if (emailReplyType === EmailReplyTypes.Reply) {
      if (!isRecipientEmailsValid(toEmailAddress)) {
        enqueueSnackbar(
          t('ADMIN.EMAIL.EMAIL_RECIPIENTS_ERROR'),
          SnackBarConfig.getError(),
        );
        return false;
      } else if (!emailReplyBody.trim()) {
        enqueueSnackbar(
          t('ADMIN.EMAIL.EMAIL_REPLY_FIELDS_ERROR'),
          SnackBarConfig.getError(),
        );
        return false;
      }
      return true;
    } else if (emailReplyType === EmailReplyTypes.ReplyAll) {
      if (
        !isRecipientEmailsValid(toEmailAddress) ||
        !isRecipientEmailsValid(ccEmailAddress)
      ) {
        enqueueSnackbar(
          t('ADMIN.EMAIL.EMAIL_RECIPIENTS_ERROR'),
          SnackBarConfig.getError(),
        );
        return false;
      } else if (!emailReplyBody.trim()) {
        enqueueSnackbar(
          t('ADMIN.EMAIL.EMAIL_REPLY_ALL_FIELDS_ERROR'),
          SnackBarConfig.getError(),
        );
        return false;
      }
      return true;
    }
  };

  const createEmailReplyFormPayload = ():
    | SendEmailReplyByIdParams
    | SendEmailReplyAllByIdParams => {
    const toRecipientsList = toEmailAddress
      .split(',')
      .map(element => element.trim());
    if (emailReplyType === EmailReplyTypes.Reply) {
      const emailReplyPayload: SendEmailReplyByIdParams = {
        emailId: emailId,
        toRecipients: toRecipientsList,
        body: emailReplyBody,
        attachments: emailReplyAttachments,
      };
      return emailReplyPayload;
    } else {
      const ccRecipientsList = ccEmailAddress
        .split(',')
        .map(element => element.trim());
      const emailReplyAllPayload: SendEmailReplyAllByIdParams = {
        emailId: emailId,
        toRecipients: toRecipientsList,
        body: emailReplyBody,
        attachments: emailReplyAttachments,
        ccRecipients: ccRecipientsList,
      };
      return emailReplyAllPayload;
    }
  };

  const handleSendEmailButton = () => {
    const isReplyFormValid = validateEmailReplyForm();

    if (isReplyFormValid) {
      const payload = createEmailReplyFormPayload();
      sendEmailReply(payload);
    }
  };

  const resetEmailResponseForm = () => {
    dispatch(resetEmailReplyForm());
    resetFileInput();
  };

  const handleDeleteEmailResponseForm = () => {
    resetEmailResponseForm();
    if (onDeleteEmailReply) {
      onDeleteEmailReply();
    }
  };

  return isEmailRecipientsDetailsLoading ? (
    <EmailRecipientsDetailsLoadingContainer emailReplyType={emailReplyType}>
      <Loader />
    </EmailRecipientsDetailsLoadingContainer>
  ) : (
    <EmailReplyContainer>
      <FromAndToAddressContainer>
        <FromStyledDiv>
          <EmailKeyWordSpanElement>
            {t('ADMIN.EMAIL.FROM')}
          </EmailKeyWordSpanElement>
          <ValueSpanElement>{fromEmailAddress}</ValueSpanElement>
        </FromStyledDiv>

        <ToStyledDiv isProdEnv={isProductionEnvironment()}>
          <EmailKeyWordSpanElement>
            {t('ADMIN.EMAIL.TO')}
          </EmailKeyWordSpanElement>
          {isProductionEnvironment() ? (
            <InputStyledElement
              type="text"
              value={toEmailAddress}
              onChange={handleToEmailAddressChange}
            />
          ) : (
            <ValueSpanElement>{toEmailAddress}</ValueSpanElement>
          )}
        </ToStyledDiv>
      </FromAndToAddressContainer>

      {emailReplyType === EmailReplyTypes.ReplyAll ? (
        <ToAndCcStyledDiv>
          <EmailKeyWordSpanElement>
            {t('ADMIN.EMAIL.CC')}
          </EmailKeyWordSpanElement>
          <InputStyledElement
            type="text"
            value={ccEmailAddress}
            onChange={handleCcEmailAddressChange}
          />
        </ToAndCcStyledDiv>
      ) : null}

      <EmailResponseTextAreaStyledDiv>
        <EmailResponseTextAreaStyled
          placeholder="input email response..."
          value={emailReplyBody}
          onChange={handleEmailReplyBodyChange}
        />
      </EmailResponseTextAreaStyledDiv>

      {emailReplyAttachments.length > 0 &&
        emailReplyAttachments.map((file, index) => (
          <EmailReplyAttachmentGrid key={index}>
            <EmailAttachmentIconImage src={AttachmentIcon} alt="..." />
            <EmailReplyAttachmentNameTypography>
              {file.name}
            </EmailReplyAttachmentNameTypography>
            <IconButton onClick={() => handleDeleteAttachment(index)}>
              <DeleteIconForEmailAttachment
                src={CloseIconForEmailAttachment}
                alt="delete"
              />
            </IconButton>
          </EmailReplyAttachmentGrid>
        ))}

      <EmailReplyActionButtonsContainer>
        <EmailReplySendAndAttachmentButtonContainer>
          <SendCustomTextButton onClick={handleSendEmailButton}>
            {t('ADMIN.EMAIL.SEND')}
          </SendCustomTextButton>
          <AttachmentCustomTextButton
            onClick={() => handleAddAttachmentButtonClick()}
          >
            <EmailAttachmentIconImage src={AttachmentIcon} alt="..." />
            {t('ADMIN.EMAIL.ADD_ATTACHMENT')}
          </AttachmentCustomTextButton>
          <EmailReplyAttachmentHiddenInput
            ref={fileInputRef}
            type="file"
            onChange={handleAddAttachmentRefClick}
          />
        </EmailReplySendAndAttachmentButtonContainer>
        <IconButton onClick={handleDeleteEmailResponseForm}>
          <DeleteImg src={DeleteIcon} alt="delete" />
        </IconButton>
      </EmailReplyActionButtonsContainer>
    </EmailReplyContainer>
  );
};

export default EmailReplyForm;
