import { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { t } from 'i18next';
import {
  MRT_RowData as MRTRowData,
  MRT_SortingState as MRTSortingState,
  MRT_ColumnDef as MRTColumnDef,
} from 'material-react-table';
import { Box, Tooltip } from '@mui/material';
import { produce } from 'immer';
import { useSnackbar } from 'notistack';
import SnackBarConfig from '@components/snackbar/SnackbarConfig';
import { getErrorMessage } from '@utils/ErrorUtils';
import {
  ERROR_CONSTANTS,
  SORT_ORDER,
} from '@constants/global-constants/constants';
import ServerSideDataGrid from '@components/custom-data-grid/ServerSideDataGrid';
import { NoDataFoundDivStyled } from 'src/styles/common/CommonStyles';
import { AdminConstants } from '@constants/admin-constants/AdminConstants';
import {
  ImgStyledDiv,
  MailBoxNameStyledDiv,
  RowStyleDiv,
  ShowMoreLink,
  StatusContainer,
  StatusStyledDiv,
  StatusWithImgStyledDiv,
  SubscribeButtonStyled,
  SubscribingButtonStyled,
  SubscriptionStatusReasonContainer,
  TypeCellSubTextStyle,
} from './EmailsMonitoringTabStyle';
import { RootState } from 'src/store/Store';
import { useDispatch, useSelector } from 'react-redux';
import { getEmailsMonitoring } from 'src/services/service-handlers/private/monitoring-handlers/MonitoringPrivateService';
import { formatDateAndTimeWithTimeZone } from '@utils/miscellaneousUtils';
import SearchAndFilterDialog, {
  ISearchAndFilterDialogProps,
} from '@components/dialog/search-and-filter-dialog/SearchAndFilterDialog';
import {
  IsEmailsDataLoadingPayloadType,
  SetIsEmailsFilterButtonClickedPayloadType,
} from '@modules/home/state/db-monitoring/DbMonitoringTypes';
import {
  changeEmailMonitoringTableName,
  setEmailFilterValues,
  setIsEmailsDataLoading,
  setIsEmailsFilterButtonClicked,
} from '@modules/home/state/db-monitoring/DbMonitoringSlice';
import { EMAIL_SCREEN_FILTER_OPTIONS_CONFIG } from './EmailScreenFilterConfiguration';
import { executeAfterTimeout } from '@modules/home/utils/Utils';
import { EmailsMonitoringParams } from 'src/services/service-handlers/private/monitoring-handlers/MonitoringServiceMapper';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import { APP } from 'src/styles/variables';
import axios, { CancelTokenSource } from 'axios';
import { updateEmailSubscription } from 'src/services/service-handlers/private/email-handlers/EmailPrivateService';
import { UpdateEmailSubscriptionParams } from 'src/services/service-handlers/private/email-handlers/EmailServiceMapper';
import {
  setShouldFilterActionButtonsDisable,
  setIsFilterResetButtonClicked,
} from '@modules/home/state/queue/QueueSlice';
import SubscriptionStatusReasonDialog from '@components/dialog/large-reason-dialog/LargeReasonDialog';

interface IEmailsMonitoringTableState {
  isLoading: boolean;
  hoveredRow: string;
  disableHoverEffect: boolean;
  totalRowCount: number;
  networkErrorState: boolean;
  shouldDialogOpen: boolean;
  isSubscribeInProgress: { [key: string]: boolean };
  isSubscriptionStatusReasonDialogOpen: boolean;
  selectedSubscriptionStatusReason: string;
}

const initialEmailsMonitoringTableState: IEmailsMonitoringTableState = {
  isLoading: true,
  hoveredRow: '',
  disableHoverEffect: false,
  totalRowCount: 0,
  networkErrorState: false,
  shouldDialogOpen: false,
  isSubscribeInProgress: {},
  isSubscriptionStatusReasonDialogOpen: false,
  selectedSubscriptionStatusReason: '',
};

interface IAutomationSettingsProps {
  isUserPortal?: boolean;
}

interface EmailMonitoringRowData {
  mailboxId: string;
  mailboxName: string;
  subscriptionStatus: string;
  subscriptionStatusReason: string;
  subscriptionCreatedTime: string;
  subscriptionExpirationTime: string;
}

const EmailsMonitoringTab = (props: IAutomationSettingsProps): ReactElement => {
  const { isUserPortal = false } = props;

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const [EmailsMonitoringTableState, setEmailsMonitoringTableState] =
    useState<IEmailsMonitoringTableState>(initialEmailsMonitoringTableState);

  const { isFilterResetButtonClicked } = useSelector(
    (state: RootState) => state.queue,
  );

  const {
    isLoading,
    totalRowCount,
    disableHoverEffect,
    networkErrorState,
    shouldDialogOpen,
    isSubscribeInProgress,
    isSubscriptionStatusReasonDialogOpen,
    selectedSubscriptionStatusReason,
  } = EmailsMonitoringTableState;

  const {
    EmailMonitoringTableName,
    isEmailsFilterButtonClicked,
    emailFilterOptions,
  } = useSelector((state: RootState) => state.dbMonitoring);

  const defaultSortOrder: MRTSortingState = [
    {
      id: SORT_ORDER.MAILBOX,
      desc: false,
    },
  ];

  // API region starts

  const cancelTokensForEmailsData = useRef<CancelTokenSource[]>([]);

  const fetchData = async (
    pageParam: number,
    fetchSize: number,
    sorting?: MRTSortingState,
  ) => {
    cancelTokensForEmailsData.current.forEach(cancelToken =>
      cancelToken.cancel(),
    );
    cancelTokensForEmailsData.current = [];

    const cancelTokenSourceForEmailsData = axios.CancelToken.source();
    cancelTokensForEmailsData.current.push(cancelTokenSourceForEmailsData);

    if (
      (emailFilterOptions && Object.entries(emailFilterOptions)?.length) ||
      isFilterResetButtonClicked
    ) {
      dispatch(
        setShouldFilterActionButtonsDisable({
          shouldFilterActionButtonsDisable: true,
        }),
      );
    }
    try {
      setEmailsMonitoringTableState(
        produce(draft => {
          draft.isLoading = true;
        }),
      );

      const sortOrder =
        sorting && sorting.length > 0 ? sorting[0] : defaultSortOrder[0];

      const payload: EmailsMonitoringParams = {
        orderBy: sortOrder.id,
        orderByKey: sortOrder.desc === true ? SORT_ORDER.DESC : SORT_ORDER.ASC,
      };

      if (emailFilterOptions?.emailStatus) {
        payload.status = emailFilterOptions.emailStatus as string;
      }

      const emailMonitoringDataRes = await getEmailsMonitoring(
        payload,
        cancelTokenSourceForEmailsData,
      );

      const dataRows: EmailMonitoringRowData[] = [];

      emailMonitoringDataRes?.data?.rows?.forEach(emailItem => {
        dataRows.push({
          mailboxId: emailItem?.mailboxId || '',
          mailboxName: emailItem?.mailboxName || '',
          subscriptionStatus: emailItem?.subscriptionStatus || '',
          subscriptionStatusReason: emailItem?.subscriptionStatusReason || '',
          subscriptionCreatedTime: emailItem?.subscriptionCreatedTime || '',
          subscriptionExpirationTime:
            emailItem?.subscriptionExpirationTime || '',
        });
      });

      setEmailsMonitoringTableState(
        produce(draft => {
          draft.totalRowCount = dataRows.length;
          draft.isLoading = false;
          draft.networkErrorState = false;
        }),
      );
      return dataRows;
    } catch (e: any) {
      const errMessage = getErrorMessage(e);
      const isNetworkError =
        e.code === ERROR_CONSTANTS.ERR_NETWORK ||
        e.statusCode === 500 ||
        (e.statusCode === 400 && pageParam === 0);

      if (isNetworkError) {
        setEmailsMonitoringTableState(
          produce(draft => {
            draft.networkErrorState = true;
          }),
        );
      }
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
      setEmailsMonitoringTableState(
        produce(draft => {
          draft.totalRowCount = 0;
          draft.isLoading = false;
        }),
      );
    } finally {
      cancelTokensForEmailsData.current =
        cancelTokensForEmailsData.current.filter(
          token => token !== cancelTokenSourceForEmailsData,
        );
      dispatch(
        setShouldFilterActionButtonsDisable({
          shouldFilterActionButtonsDisable: false,
        }),
      );
      dispatch(setIsFilterResetButtonClicked({ isResetButtonClicked: false }));
    }
  };

  // API region ends

  useEffect(() => {
    return () => {
      if (cancelTokensForEmailsData.current.length) {
        cancelTokensForEmailsData.current.forEach(cancelToken =>
          cancelToken.cancel(),
        );
      }
    };
  }, []);

  useEffect(() => {
    const payload: IsEmailsDataLoadingPayloadType = {
      isEmailsDataLoadingPayload: isLoading,
    };
    dispatch(setIsEmailsDataLoading(payload));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const handleFilterButtonClick = () => {
    setEmailsMonitoringTableState(
      produce(draft => {
        draft.shouldDialogOpen = true;
      }),
    );
  };

  const dialogClose = () => {
    setEmailsMonitoringTableState(
      produce(draft => {
        draft.shouldDialogOpen = false;
      }),
    );
  };

  const handleApplyFilterBtnClick = (data: {
    [key: string]: string | boolean;
  }) => {
    if (!data?.searchValue) {
      delete data?.searchKey;
      delete data?.searchValue;
    }

    dispatch(setEmailFilterValues({ data: data }));

    const values = Object.values(data);

    for (let i = 0; i < values.length; i++) {
      const valueItem = values[i];
      if (valueItem) {
        dispatch(changeEmailMonitoringTableName());
        break;
      }
    }

    executeAfterTimeout(() => {
      setEmailsMonitoringTableState(
        produce(draft => {
          draft.shouldDialogOpen = false;
        }),
      );
    }, 100);
  };

  const handleClearFilterBtnClick = () => {
    if (emailFilterOptions?.searchValue || emailFilterOptions?.emailStatus) {
      dispatch(
        setEmailFilterValues({
          data: {
            searchKey: '',
            searchValue: '',
            emailStatus: '',
          },
        }),
      );
      dispatch(changeEmailMonitoringTableName());
    }

    executeAfterTimeout(() => {
      setEmailsMonitoringTableState(
        produce(draft => {
          draft.shouldDialogOpen = false;
        }),
      );
    }, 100);
  };

  const handleSubscribeButton = async (emailId: string, rowId: string) => {
    setEmailsMonitoringTableState(
      produce(draft => {
        draft.isSubscribeInProgress[rowId] = true;
      }),
    );
    try {
      const payload: UpdateEmailSubscriptionParams = {
        emailId: emailId,
      };
      const updateEmailSubscriptionRes = await updateEmailSubscription(payload);
      enqueueSnackbar(
        updateEmailSubscriptionRes.data.message,
        SnackBarConfig.getError(),
      );
    } catch (e: any) {
      const errMessage = getErrorMessage(e);
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
    } finally {
      setEmailsMonitoringTableState(
        produce(draft => {
          draft.isSubscribeInProgress[rowId] = false;
        }),
      );
    }
  };

  useEffect(() => {
    if (isEmailsFilterButtonClicked) {
      const setIsEmailsFilterButtonClickedPayload: SetIsEmailsFilterButtonClickedPayloadType =
        {
          isEmailsFilterButtonClickedPayload: false,
        };
      dispatch(
        setIsEmailsFilterButtonClicked(setIsEmailsFilterButtonClickedPayload),
      );
      handleFilterButtonClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEmailsFilterButtonClicked]);

  const handleOpenFlagDetailsDialog = (reason: string) => {
    setEmailsMonitoringTableState(
      produce(draft => {
        draft.isSubscriptionStatusReasonDialogOpen = true;
        draft.selectedSubscriptionStatusReason = reason;
      }),
    );
  };

  const handleCloseFlagDetailsDialog = () => {
    setEmailsMonitoringTableState(
      produce(draft => {
        draft.isSubscriptionStatusReasonDialogOpen = false;
      }),
    );
  };

  // render functions starts

  const renderHeaderWithTooltip = (column: any) => {
    return (
      <Tooltip title={`${column.columnDef.header}`}>
        <div>{column.columnDef.header}</div>
      </Tooltip>
    );
  };

  const renderMailboxName = (rowData: { mailboxName: string }) => {
    const { mailboxName } = rowData;
    return (
      <div>
        <MailBoxNameStyledDiv>{mailboxName}</MailBoxNameStyledDiv>
      </div>
    );
  };

  const renderSubscriptionStatusReasonDialog = () => {
    return (
      <SubscriptionStatusReasonDialog
        isDialogOpen={isSubscriptionStatusReasonDialogOpen}
        onClose={handleCloseFlagDetailsDialog}
        subscriptionStatusReason={selectedSubscriptionStatusReason}
      />
    );
  };

  const renderSubscriptionStatusReason = (subscriptionStatusReason = '') => {
    const maxCharactersForSubscriptionStatusReason = 70;

    const isSubscriptionStatusReasonExceedMaxLength =
      subscriptionStatusReason.length >
      maxCharactersForSubscriptionStatusReason;

    if (subscriptionStatusReason.length < 1) {
      return '';
    } else {
      return (
        <SubscriptionStatusReasonContainer>
          {isSubscriptionStatusReasonExceedMaxLength
            ? `${subscriptionStatusReason.substring(
                0,
                maxCharactersForSubscriptionStatusReason,
              )}... `
            : subscriptionStatusReason}
          {isSubscriptionStatusReasonExceedMaxLength && (
            <ShowMoreLink
              onClick={() =>
                handleOpenFlagDetailsDialog(subscriptionStatusReason)
              }
            >
              {t('ADMIN.QUEUE_TABS.FLAG_QUEUE_ITEM.VIEW_MORE')}
            </ShowMoreLink>
          )}
        </SubscriptionStatusReasonContainer>
      );
    }
  };

  const renderSubscriptionStatus = (rowData: {
    subscriptionStatus: string;
    subscriptionStatusReason: string;
  }) => {
    const { subscriptionStatus, subscriptionStatusReason } = rowData;

    const colorForStatus =
      subscriptionStatus.toLowerCase() ===
      AdminConstants.MONITORING_TABS.EMAILS_MONITORING_TAB.ACTIVE
        ? APP.PALETTE.SUCCESS.MAIN
        : subscriptionStatus.toLowerCase() ===
          AdminConstants.MONITORING_TABS.EMAILS_MONITORING_TAB.IN_ACTIVE
        ? APP.PALETTE.ERROR.MAIN
        : null;

    return (
      <div>
        <StatusWithImgStyledDiv>
          <ImgStyledDiv>
            <CircleRoundedIcon sx={{ height: '18px', color: colorForStatus }} />
          </ImgStyledDiv>
          <StatusContainer>
            <StatusStyledDiv>{subscriptionStatus ?? '-'}</StatusStyledDiv>
          </StatusContainer>
        </StatusWithImgStyledDiv>
        {subscriptionStatus.toLowerCase() === AdminConstants.USERS.INACTIVE ? (
          <TypeCellSubTextStyle>
            {renderSubscriptionStatusReason(subscriptionStatusReason)}
          </TypeCellSubTextStyle>
        ) : null}
      </div>
    );
  };

  const renderSubscriptionCreatedTime = (rowData: {
    subscriptionCreatedTime: Date;
  }) => {
    const { subscriptionCreatedTime } = rowData;
    const formattedSubscriptionCreatedTime = subscriptionCreatedTime
      ? formatDateAndTimeWithTimeZone(subscriptionCreatedTime)
      : '-';
    return (
      <div>
        <RowStyleDiv>{formattedSubscriptionCreatedTime}</RowStyleDiv>
      </div>
    );
  };

  const renderSubscriptionExpirationTime = (rowData: {
    subscriptionExpirationTime: Date;
  }) => {
    const { subscriptionExpirationTime } = rowData;
    const formattedSubscriptionExpirationTime = subscriptionExpirationTime
      ? formatDateAndTimeWithTimeZone(subscriptionExpirationTime)
      : '-';
    return (
      <div>
        <RowStyleDiv>{formattedSubscriptionExpirationTime}</RowStyleDiv>
      </div>
    );
  };

  const renderActionButton = (
    rowData: { subscriptionStatus: string; mailboxId: string },
    row: any,
  ) => {
    const { subscriptionStatus, mailboxId } = rowData;
    const rowId = row?.original?.mailboxId;

    const isSubscribeButtonDisabled =
      subscriptionStatus === AdminConstants.USERS.ACTIVE ||
      isSubscribeInProgress[rowId];

    const subscribeButtonText =
      subscriptionStatus === AdminConstants.USERS.ACTIVE
        ? t(
            'ADMIN.SETTINGS_TAB.MONITORING_TAB.EMAILS_MONITORING.SUBSCRIBED_BUTTON_TEXT',
          )
        : t(
            'ADMIN.SETTINGS_TAB.MONITORING_TAB.EMAILS_MONITORING.SUBSCRIBE_BUTTON_TEXT',
          );

    return isSubscribeInProgress[rowId] ? (
      <SubscribingButtonStyled
        disabled={isSubscribeButtonDisabled}
        variant="outlined"
      >
        {t(
          'ADMIN.SETTINGS_TAB.MONITORING_TAB.EMAILS_MONITORING.SUBSCRIBING_BUTTON_TEXT',
        )}
      </SubscribingButtonStyled>
    ) : (
      <SubscribeButtonStyled
        disabled={isSubscribeButtonDisabled}
        variant="outlined"
        onClick={() => handleSubscribeButton(mailboxId, rowId)}
      >
        {t(subscribeButtonText)}
      </SubscribeButtonStyled>
    );
  };

  const renderSearchAndFilterDropdownDialog = (
    dialogProps: ISearchAndFilterDialogProps,
  ): ReactElement => {
    return <SearchAndFilterDialog {...dialogProps} />;
  };

  // render functions ends

  const columnsData: MRTColumnDef<MRTRowData>[] = useMemo(
    () => {
      const baseColumns: MRTColumnDef<MRTRowData>[] = [
        {
          accessorKey:
            AdminConstants.MONITORING_TABS.EMAILS_MONITORING_TAB
              .COLUMN_ACCESSOR_KEY.MAIL_BOX_NAME,
          header: t(
            'ADMIN.SETTINGS_TAB.MONITORING_TAB.EMAILS_MONITORING.MAIL_BOX_NAME',
          ),
          Header: ({ column }: any) => renderHeaderWithTooltip(column),
          accessorFn: row => ({
            mailboxName: row?.mailboxName ?? '',
          }),
          Cell: ({ renderedCellValue }: any) =>
            renderMailboxName(renderedCellValue),
          grow: true,
        },
        {
          accessorKey:
            AdminConstants.MONITORING_TABS.EMAILS_MONITORING_TAB
              .COLUMN_ACCESSOR_KEY.SUBSCRIPTION_STATUS,
          header: t(
            'ADMIN.SETTINGS_TAB.MONITORING_TAB.EMAILS_MONITORING.SUBSCRIPTION_STATUS',
          ),
          Header: ({ column }: any) => renderHeaderWithTooltip(column),
          accessorFn: row => ({
            subscriptionStatus: row?.subscriptionStatus ?? '',
            subscriptionStatusReason: row?.subscriptionStatusReason ?? '',
          }),
          Cell: ({ renderedCellValue }: any) =>
            renderSubscriptionStatus(renderedCellValue),
          grow: true,
          enableSorting: false,
        },
        {
          accessorKey:
            AdminConstants.MONITORING_TABS.EMAILS_MONITORING_TAB
              .COLUMN_ACCESSOR_KEY.SUBSCRIPTION_CREATED_TIME,
          header: t(
            'ADMIN.SETTINGS_TAB.MONITORING_TAB.EMAILS_MONITORING.SUBSCRIPTION_CREATED_TIME',
          ),
          Header: ({ column }: any) => renderHeaderWithTooltip(column),
          accessorFn: row => ({
            subscriptionCreatedTime: row?.subscriptionCreatedTime ?? '',
          }),
          Cell: ({ renderedCellValue, row }: any) =>
            renderSubscriptionCreatedTime(renderedCellValue),
          grow: true,
        },
        {
          accessorKey:
            AdminConstants.MONITORING_TABS.EMAILS_MONITORING_TAB
              .COLUMN_ACCESSOR_KEY.SUBSCRIPTION_EXPIRATION_TIME,
          header: t(
            'ADMIN.SETTINGS_TAB.MONITORING_TAB.EMAILS_MONITORING.SUBSCRIPTION_EXPIRATION_TIME',
          ),
          Header: ({ column }: any) => renderHeaderWithTooltip(column),
          accessorFn: row => ({
            subscriptionExpirationTime: row?.subscriptionExpirationTime ?? '',
          }),
          Cell: ({ renderedCellValue, row }: any) =>
            renderSubscriptionExpirationTime(renderedCellValue),
          grow: true,
        },
        {
          accessorKey:
            AdminConstants.MONITORING_TABS.EMAILS_MONITORING_TAB
              .COLUMN_ACCESSOR_KEY.ACTION_BUTTON,
          header: t(
            'ADMIN.SETTINGS_TAB.MONITORING_TAB.EMAILS_MONITORING.ACTIONS',
          ),
          Header: ({ column }: any) => renderHeaderWithTooltip(column),
          accessorFn: row => ({
            subscriptionStatus: row?.subscriptionStatus ?? '',
            mailboxId: row?.mailboxId ?? '',
          }),
          Cell: ({ renderedCellValue, row }: any) =>
            renderActionButton(renderedCellValue, row),
          grow: true,
          enableSorting: false,
        },
      ];
      return baseColumns;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSubscribeInProgress],
  );

  return (
    <>
      <Box>
        {networkErrorState ? (
          <NoDataFoundDivStyled>{t('NO_DATA_FOUND')}</NoDataFoundDivStyled>
        ) : (
          <ServerSideDataGrid
            isUserPortal={isUserPortal}
            fetchDataFnQueryKeyName={EmailMonitoringTableName}
            fetchDataFn={fetchData}
            dataFetchSize={
              AdminConstants.QUEUE.QUEUE_ASSIGNMENTS.USERS_DATA_LIMIT
            }
            totalRowsCount={totalRowCount}
            columns={columnsData}
            state={{ isLoading }}
            enableBottomToolbar={false}
            muiTableBodyRowProps={({ row }) => {
              return {
                onMouseEnter: () => {
                  if (disableHoverEffect) return;
                  setEmailsMonitoringTableState(
                    produce(draft => {
                      draft.hoveredRow = row?.original?.taskTypeId || '';
                    }),
                  );
                },
                onMouseLeave: () => {
                  if (disableHoverEffect) return;
                  setEmailsMonitoringTableState(
                    produce(draft => {
                      draft.hoveredRow = ' ';
                    }),
                  );
                },
              };
            }}
            muiCircularProgressProps={{
              color: 'secondary',
              thickness: 5,
              size: 55,
            }}
            muiSkeletonProps={{
              animation: 'pulse',
              height: 28,
            }}
            enableTableFooter={false}
            getRowId={row => row?.original?.mailboxId ?? ''}
            layoutMode="grid"
          />
        )}
      </Box>
      {renderSearchAndFilterDropdownDialog({
        open: shouldDialogOpen,
        configuration: EMAIL_SCREEN_FILTER_OPTIONS_CONFIG,
        handleCloseBtnClick: dialogClose,
        handleApplyFilterClick: data => handleApplyFilterBtnClick(data),
        handleClearFilterClick: handleClearFilterBtnClick,
      })}
      {isSubscriptionStatusReasonDialogOpen &&
        renderSubscriptionStatusReasonDialog()}
    </>
  );
};

export default EmailsMonitoringTab;
