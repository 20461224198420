export const AdminConstants = {
  ADMIN: 'admin',
  SIDE_NAV_BAR_MENU_ITEMS: {
    DASHBOARD: 'dashboard',
    QUEUE: 'queue',
    USERS: 'users',
    EMAILS: 'emails',
    NOTIFICATIONS: 'notifications',
    SETTINGS: 'settings',
    PROFILE: 'profile',
  },
  USERS: {
    ACTIVE: 'active',
    USER_TABLE_DATA_LIMIT: 50,
    INACTIVE: 'inactive',
    USERS_TABLE_HEADERS_ID: {
      NAME: 'name',
      EMAIL: 'email',
      PHONE: 'phone',
      TITLE: 'title',
      ROLE: 'role',
    },
    ACTIVE_USERS_DATA: 'active-users-data',
    INACTIVE_USERS_DATA: 'inactive-users-data',
    USERS_DATA: 'users-data',
    USERS_KEBAB_MENU_OPTIONS: {
      VIEW_PROFILE: 'View Profile',
      MARK_INACTIVE: 'Mark Inactive',
      MARK_ACTIVE: 'Mark Active',
    },
    ALL: 'all',
    ALL_CAPITALIZED: 'All',
    PRIMARY: 'primary',
    STATE: 'State',
    NSA: 'NSA',
    NAME: 'name',
    ALL_STATES: 'All States',
    ALL_STATE: 'allState',
    STATENSA: 'stateNSA',
    USER_SETTINGS: {
      SETTINGS: 'settings',
      ROLE_ADMIN: 'Admin',
      MANAGER: 'Manager',
      EMPLOYEE: 'Employee',
    },
  },
  AUTOMATION_SETTINGS: {
    AUTOMATION_SETTINGS_TABLE_NAME_WITHOUT_FILTER:
      'Automation-Settings-Table-Without-Filter',
    AUTOMATION_SETTINGS_TABLE_NAME_WITH_FILTERS:
      'Automation-Setting-Table-With-Filters',
    COLUMN_ACCESSOR_KEY: {
      TASK_TYPE: 'taskType',
      TASK_CATEGORY: 'taskCategory',
      TASK_TYPE_ID: 'taskTypeId',
      IS_PAUSED: 'isPaused',
      TASK_AUTOMATED_ACTION: 'action',
      LAST_REPROCESS_STATUS: 'lastReprocessStatus',
    },
  },
  MONITORING_TABS: {
    DB_QUERY_AND_DATA_EXCEPTIONS_MONITORING_TAB: {
      COLUMN_ACCESSOR_KEY: {
        TASK_TYPE: 'taskType',
        CONNECTION_NAME: 'Connection Name',
        LAST_RUN_TIME: 'lastRunTime',
        LAST_RUN_STATUS: 'lastRunStatus',
        LAST_SUCCESSFUL_TIME: 'lastSuccessfulTime',
        ACTION: 'Action',
      },
      COMPLETED: 'completed',
      FAILED: 'failed',
      IN_PROGRESS: 'inprogress',
      DATA_EXCEPTIONS: 'Data Exceptions',
    },
    EMAILS_MONITORING_TAB: {
      COLUMN_ACCESSOR_KEY: {
        MAIL_BOX_NAME: 'mailboxName',
        SUBSCRIPTION_STATUS: 'subscriptionStatus',
        SUBSCRIPTION_CREATED_TIME: 'subscriptionCreatedTime',
        SUBSCRIPTION_EXPIRATION_TIME: 'subscriptionExpirationTime',
        ACTION_BUTTON: 'actionButton',
      },
      ACTIVE: 'active',
      IN_ACTIVE: 'inactive',
    },
  },
  EMAIL_SETTINGS: {
    ALL: 'all',
    TABLE_COLUMNS: {
      FROM_EMAIL: 'fromEmail',
      BODY: 'body',
      CLASSIFICATIONS: 'classifications',
      STATE_OR_NSA: 'state/NSA',
      RECEIVED_AT: 'receivedAt',
    },
    EMAIL_SETTINGS_TABLE_NAME_WITHOUT_FILTER:
      'Email-Settings-Table-Without-Filter',
    EMAIL_SETTINGS_TABLE_WITH_FILTERS: 'Email-Setting-Table-With-Filters',
    EMAIL_SETTINGS_TABLE_WITH_SEARCH: 'Email-Setting-Table-With-Search',
    EMAIL_SETTINGS_TABLE_CLEAR: 'Email-Setting-Table-With-Clear',
    EMAIL_SETTINGS_REFETCH_DATA: 'Email-Setting-Refetch-Data',
    EMAIL_SETTING_DETAILS: {
      PATHNAME: 'email-settings-details',
      MONITORING: {
        DB_QUERY_AND_DB_EXCEPTION_TABLE:
          'DB-Query-And-DB-Exceptions-Monitoring-Table',
        EMAIL_MONITORING_TABLE: 'Email-Monitoring-Table',
      },
    },
  },
  EMAIL: {
    EMAIL_TABLE_DATA_LIMIT: 50,
    EMAIL_TABLE_NAME: 'email-table',
    TABLE_COLUMNS: {
      FROM_EMAIL: 'fromEmail',
      BODY: 'body',
      RECEIVED_AT: 'received_at',
    },
  },
  QUEUE_AI: {
    QUEUE_TABLE_DATA_LIMIT: 50,
    QUEUE_AI_TABLE: {
      CASE_ID: 'caseId',
      TASK_TYPE: 'taskType',
      CUSTOMER_NAME: 'customerName',
      NSA_OR_STATE: 'nsaState',
      PAYOR_NAME: 'payorName',
      DUE_DATE: 'dueDate',
      COMPLETED_ON: 'completedOn',
      EMAIL_DATE: 'emailDate',
      STATUS: 'status',
      ASSIGNED_USER: 'assignedUser',
    },
  },
  QUEUE: {
    ASSIGNED_USER_ID: 'assignedUserId',
    OPEN: 'open',
    ASSIGNED: 'assigned',
    UN_ASSIGNED: 'unassigned',
    UN_ASSIGN: 'Unassign',
    TOTAL: 'total',
    ALL: 'all',
    QUEUE_TABLE: {
      QUEUE_TABLE_NAME: 'queue-table',
    },
    QUEUE_ASSIGNMENTS: {
      USERS_DATA_LIMIT: 50,
      MAX_ASSIGNMENT_CONTROLLER: 'maxAssignments' as const,
      USERS_TABLE_FN_NAME: 'queue-assignments-by-user',
      UNASSIGNED: 'unassigned',
      SEARCH_AND_FILTER_OPTIONS: 'TempStoringSearchAndFilterOptionsInLocal',
      TABLE_HEADERS_ID: {
        USER: 'user',
        TOTAL: 'total',
        HIGH: 'high',
        MEDIUM: 'medium',
        LOW: 'low',
      },
      QUERY_PARAM_NAMES: {
        STATUS: 'taskStatus',
        TASK_PRIORITY: 'taskPriority',
        TASK_CATEGORY: 'taskCategoryId',
        ASSIGNED_USER: 'assignedUserId',
        STATE_ID: 'stateId',
        STATE_NSA: 'nsaState',
        PRIORITY: 'priority',
        STATE: 'state',
      },
    },
    DB_QUERY_SHARED_SERVICES: 'DB Query Shared Services',
    DB_QUERY_NSA: 'DB Query NSA',
    DB_QUERY_STATE: 'DB Query State',
    DB_QUERY_DUAL_INELIGIBILITY: 'DB Query Dual Ineligibility',
    DATA_EXCEPTIONS_SHARED_SERVICES: 'Data Exceptions Shared Services',
    DATA_EXCEPTIONS_STATE_TEAM: 'Data Exceptions State Team',
    DATA_EXCEPTIONS_ARBIT_TEAM: 'Data Exceptions Arbit Team',
  },
};

export const DashboardConstants = {
  INFO_CARD: {
    OPEN: 'open',
    COMPLETED: 'completed',
    FORMAL: 'Formal',
  },
  FILTER_CONSTANTS: {
    ASSIGNED: 'ASSIGNED',
    TASK_OWNER_TYPE: 'TASK_OWNER_TYPE',
    STATE_NSA: 'STATE_NSA',
    TIME_RANGE: 'TIME_RANGE',
  },
  TIME_SPAN: {
    DAY: 'd',
    MONTH: 'm',
    YEAR: 'y',
  },
  QUEUE_TABLE: {
    COLUMNS: {
      QUEUE_TYPE: 'queueType',
      LABEL: 'label',
      TOTAL: 'total',
      HIGH: 'high',
      MEDIUM: 'medium',
      LOW: 'low',
    },
  },
  USER_TABLE: {
    USER_QUEUE_TABLE_NAME: 'user-queue-table',
    COLUMNS: {
      USER: 'user',
      USER_NAME: 'userName',
      TOTAL: 'total',
      HIGH: 'high',
      MEDIUM: 'medium',
      LOW: 'low',
    },
  },
};
