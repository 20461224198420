import React, {
  ReactElement,
  useState,
  useRef,
  useEffect,
  useMemo,
  memo,
  ChangeEvent,
  useCallback,
  forwardRef,
  MutableRefObject,
} from 'react';
import { Autocomplete, Avatar, Box, Popper } from '@mui/material';
import {
  LoaderContainer,
  LoadingGridForDropdownSelectInput,
  LoadingGridForVirtualizedSearchableSelectInput,
  TextFieldWrapperStyled,
} from './VirtualizedSearchableSelectInputStyle';
import { AssignedUser } from 'src/services/service-handlers/private/queue-handlers/QueueServiceMapper';
import Loader from '@components/loaders/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/Store';
import { getProfilePicture } from 'src/services/service-handlers/private/profile-details-handlers/ProfileDetailsPrivateService';
import { produce } from 'immer';
import SnackBarConfig from '@components/snackbar/SnackbarConfig';
import { enqueueSnackbar } from 'notistack';
import { getErrorMessage } from '@utils/ErrorUtils';
import { t } from 'i18next';
import { ProfilePicUrlsWithLoaderFlagByIdType } from '@modules/home/state/queue/QueueTypes';
import { setProfilePicsUrlsWithLoaderFlagById } from '@modules/home/state/queue/QueueSlice';
import { getReassignUsersListByTaskId } from 'src/services/service-handlers/private/tasks-handlers/TasksPrivateService';
import { ProfilePicRefType } from 'src/types/Types';
import { AdminConstants } from '@constants/admin-constants/AdminConstants';
import { useDebouncedCallback } from 'use-debounce';
import {
  BATCH_SIZE,
  BATCH_SIZE_FOR_PROFILE_PIC_CACHE,
  SEARCH_DEBOUNCE_DELAY_IN_MS,
} from '@constants/global-constants/constants';
import { GetAllUsersFromOurSystemQueryParam } from 'src/services/service-handlers/private/users-handlers/UsersServiceMapper';
import { GetAllUsersFromOurSystemQueryParamFormatEnum } from 'src/enums/admin-enums/AdminEnums';
import { fetchAllUsersFromOurSystem } from 'src/services/service-handlers/private/users-handlers/UsersPrivateService';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';
import { GetReassignUsersListByTaskIdQueryParam } from 'src/services/service-handlers/private/tasks-handlers/TasksServiceMapper';
import { SkeletonItem } from '@components/skeleton-item/SkeletonItem';

interface IVirtualizedSearchableSelectInputProps {
  defaultOption?: AssignedUser;
  showProfilePic?: boolean;
  handlePopOverOpenClose?: (isModalOpen?: boolean) => void;
  openDropdown?: boolean;
  size?: 'small' | 'medium';
  clearIcon?: boolean;
  disabled?: boolean;
  handleOnChange?: (value: string) => void;
  width?: string;
  isDefaultOptionRequired?: boolean;
  handleOptionSelectChange?: (value: any) => void;
  shouldLoadDefaultProfilePic?: boolean;
  shouldFetchUsersOptionsListByTaskId?: boolean;
  shouldLoadProfilePicsForBulkReAssign?: boolean;
  taskId?: string;
  fetchedProfilePicRef: MutableRefObject<ProfilePicRefType>;
  setFetchProfilePicRef: (userId: string) => void;
  showUnassignOption?: boolean;
  virtuosoRef: MutableRefObject<VirtuosoHandle | null>;
  scrollPositionRef: MutableRefObject<number>;
}

type VirtualizedSearchableSelectInputStateType = {
  isReassignUsersListByTaskIdLoading: boolean;
  reassignUsersListByTaskId: AssignedUser[];
  usersList: [] | AssignedUser[];
  isUsersListLoading: boolean;
  isMoreUsersListLoading: boolean;
  limit: number;
  offset: number;
  totalCount: number;
  selectedUser: AssignedUser;
  searchInputText: string;
};

const initialVirtualizedSearchableSelectInputState: VirtualizedSearchableSelectInputStateType =
  {
    isReassignUsersListByTaskIdLoading: true,
    reassignUsersListByTaskId: [],
    usersList: [],
    isUsersListLoading: false,
    isMoreUsersListLoading: false,
    limit: 20,
    offset: 0,
    totalCount: 0,
    selectedUser: {
      id: '-1',
      profilePicId: null,
      name: null,
      email: null,
      contactEmail: null,
    },
    searchInputText: '',
  };

const VirtualizedSearchableSelectInput = (
  props: IVirtualizedSearchableSelectInputProps,
): ReactElement => {
  const {
    showProfilePic = true,
    defaultOption,
    handlePopOverOpenClose,
    handleOnChange,
    openDropdown,
    size = 'small',
    clearIcon = false,
    disabled = false,
    width,
    isDefaultOptionRequired = true,
    handleOptionSelectChange,
    shouldLoadDefaultProfilePic = false,
    shouldFetchUsersOptionsListByTaskId = false,
    shouldLoadProfilePicsForBulkReAssign = false,
    taskId = '',
    fetchedProfilePicRef,
    setFetchProfilePicRef,
    showUnassignOption = true,
    virtuosoRef,
    scrollPositionRef,
  } = props;

  const dispatch = useDispatch();

  const { profilePicsUrlsWithLoaderFlagById } = useSelector(
    (state: RootState) => state.queue,
  );

  const [
    virtualizedSearchableSelectInputState,
    setVirtualizedSearchableSelectInputState,
  ] = useState<VirtualizedSearchableSelectInputStateType>(
    initialVirtualizedSearchableSelectInputState,
  );

  const {
    isReassignUsersListByTaskIdLoading,
    reassignUsersListByTaskId,
    usersList,
    isUsersListLoading,
    isMoreUsersListLoading,
    limit,
    offset,
    totalCount,
    searchInputText,
  } = virtualizedSearchableSelectInputState;

  const UnassignedObj = {
    contactEmail: null,
    email: null,
    id: '-1',
    name: 'Unassigned',
    profilePicId: null,
  };

  const UnassignObj = {
    contactEmail: null,
    email: null,
    id: '-1',
    name: AdminConstants.QUEUE.UN_ASSIGN,
    profilePicId: null,
  };

  const defaultEmptyValue = {
    contactEmail: null,
    email: '',
    id: '-1',
    name: '',
    profilePicId: null,
  };

  const isRestoringScrollRef = useRef(false);
  const defaultValue = defaultOption || UnassignedObj;
  const [value, setValue] = useState<any>(
    isDefaultOptionRequired ? defaultValue || null : defaultEmptyValue,
  );
  const inputRef = useRef<HTMLInputElement>(null);

  const restoreScroll = () => {
    if (virtuosoRef.current && scrollPositionRef.current) {
      isRestoringScrollRef.current = true;

      setTimeout(() => {
        virtuosoRef.current?.scrollTo({ top: scrollPositionRef.current });
        isRestoringScrollRef.current = false;
      }, 1000);
    }
  };

  // memoize options
  const options = useMemo(() => {
    if (
      isReassignUsersListByTaskIdLoading &&
      shouldFetchUsersOptionsListByTaskId &&
      !shouldLoadProfilePicsForBulkReAssign
    ) {
      return [];
    }
    const optionsWithUnassignOption = shouldFetchUsersOptionsListByTaskId
      ? reassignUsersListByTaskId
      : usersList;

    return optionsWithUnassignOption;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    usersList,
    reassignUsersListByTaskId,
    shouldFetchUsersOptionsListByTaskId,
    defaultValue,
    isReassignUsersListByTaskIdLoading,
  ]);

  // API region starts

  const profilePicsCacheRef = useRef<ProfilePicUrlsWithLoaderFlagByIdType>({});
  const pendingDispatchRef = useRef<ProfilePicUrlsWithLoaderFlagByIdType>({});

  const moveProfilePicsFromPendingCacheIntoRedux = () => {
    Object.entries(pendingDispatchRef.current).forEach(
      ([id, { profilePicUrl, isProfilePicLoading }]) => {
        dispatch(
          setProfilePicsUrlsWithLoaderFlagById({
            userId: id,
            profilePicUrl,
            isProfilePicLoading,
          }),
        );
      },
    );
    pendingDispatchRef.current = {}; // Clear batch, keep cache
  };

  const fetchProfilePicById = async (
    userId: string,
    profilePictureId: string,
    isDefaultOption?: boolean,
  ) => {
    if (!userId || !profilePictureId) return '';

    // Check localRef before fetching
    if (!isDefaultOption && profilePicsCacheRef.current[userId]) {
      return profilePicsCacheRef.current[userId].profilePicUrl;
    }

    // Mark as loading in refs
    const profilePicPayload = {
      profilePicUrl: '',
      isProfilePicLoading: true,
    };

    profilePicsCacheRef.current[userId] = profilePicPayload;
    pendingDispatchRef.current[userId] = profilePicPayload;

    // Dispatch immediately if default option (avoids intermediate state)
    if (isDefaultOption) {
      dispatch(
        setProfilePicsUrlsWithLoaderFlagById({
          userId,
          profilePicUrl: '',
          isProfilePicLoading: true,
        }),
      );
    }

    try {
      const { data } = await getProfilePicture(userId);

      if (data?.mimeType && data?.content?.data?.length) {
        const blobImg = new Blob([new Uint8Array(data.content.data)], {
          type: data.mimeType,
        });
        const imageUrl = URL.createObjectURL(blobImg);

        // Update refs with fetched image
        profilePicsCacheRef.current[userId] = {
          profilePicUrl: imageUrl,
          isProfilePicLoading: false,
        };

        pendingDispatchRef.current[userId] = {
          profilePicUrl: imageUrl,
          isProfilePicLoading: false,
        };

        // Dispatch immediately if default option
        if (isDefaultOption) {
          dispatch(
            setProfilePicsUrlsWithLoaderFlagById({
              userId,
              profilePicUrl: imageUrl,
              isProfilePicLoading: false,
            }),
          );
        }
      } else {
        enqueueSnackbar(
          t('ADMIN.PROFILE_PICTURE_VALIDATION.PROFILE_PICTURE_NOT_FOUND'),
          SnackBarConfig.getError(),
        );
      }
    } catch (error) {
      enqueueSnackbar(getErrorMessage(error), SnackBarConfig.getError());
    } finally {
      // Dispatch batch if threshold met
      if (
        Object.keys(pendingDispatchRef.current).length >=
        BATCH_SIZE_FOR_PROFILE_PIC_CACHE
      ) {
        moveProfilePicsFromPendingCacheIntoRedux();
      }
    }

    return profilePicsCacheRef.current[userId].profilePicUrl;
  };

  const batchFetchProfilePics = async (users: AssignedUser[]) => {
    for (let i = 0; i < users.length; i += BATCH_SIZE) {
      const batch = users.slice(i, i + BATCH_SIZE);

      // Filter out invalid users (missing profilePictureId)
      const validBatch = batch.filter(user => user?.profilePicId?.length);

      if (validBatch?.length === 0) continue; // Skip empty batches

      // Mark users as fetching before calling API
      validBatch.forEach(user => setFetchProfilePicRef(user?.id ?? ''));

      Promise.all(
        validBatch.map(({ id, profilePicId }) =>
          fetchProfilePicById(id ?? '', profilePicId ?? ''),
        ),
      );
    }
  };

  const getProfilePicsOfReassignUsers = async (
    updatedUsersList: AssignedUser[],
  ) => {
    const pendingUsers = updatedUsersList.filter(
      user =>
        user?.id &&
        user?.profilePicId &&
        !profilePicsUrlsWithLoaderFlagById[user.id] &&
        !fetchedProfilePicRef.current[user.id],
    );

    if (pendingUsers.length > 0) {
      await batchFetchProfilePics(pendingUsers);
    }
  };

  const fetchReAssignUsersListByTaskId = async (fromBeginning = false) => {
    try {
      if (!fromBeginning) {
        setVirtualizedSearchableSelectInputState(
          produce(draft => {
            draft.isMoreUsersListLoading = true;
          }),
        );
      } else {
        setVirtualizedSearchableSelectInputState(
          produce(draft => {
            draft.offset = 0;
            draft.totalCount = 0;
          }),
        );
      }

      const newOffset = fromBeginning ? 0 : offset + limit;

      if (!fromBeginning && newOffset >= totalCount) {
        return;
      }

      const payLoadData: GetReassignUsersListByTaskIdQueryParam = {
        limit: limit,
        offset: newOffset,
      };

      if (searchInputText.length > 0) {
        payLoadData.searchValue = searchInputText;
      }

      const userListRes = await getReassignUsersListByTaskId(
        taskId,
        payLoadData,
      );

      const newUsersList = fromBeginning
        ? userListRes?.data?.rows ?? []
        : [...usersList, ...(userListRes?.data?.rows ?? [])];

      const addUnassignedOption =
        value?.name?.toLowerCase() !== UnassignedObj.name.toLowerCase() &&
        value?.name?.toLowerCase() !== UnassignObj.name.toLowerCase() &&
        showUnassignOption;

      if (addUnassignedOption && fromBeginning) {
        newUsersList.unshift(UnassignObj);
      }

      setVirtualizedSearchableSelectInputState(
        produce(draft => {
          draft.reassignUsersListByTaskId = newUsersList;
          draft.totalCount = userListRes.data.count;
          draft.offset = newOffset;
        }),
      );

      setTimeout(() => {
        restoreScroll();
      }, 0);
    } catch (e) {
      const errMessage = getErrorMessage(e);
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
    } finally {
      if (!fromBeginning) {
        setVirtualizedSearchableSelectInputState(
          produce(draft => {
            draft.isMoreUsersListLoading = false;
          }),
        );
      } else {
        setVirtualizedSearchableSelectInputState(
          produce(draft => {
            draft.isUsersListLoading = false;
            draft.isReassignUsersListByTaskIdLoading = false;
          }),
        );
      }
    }
  };

  const fetchData = async (fromBeginning = false) => {
    try {
      if (!fromBeginning) {
        setVirtualizedSearchableSelectInputState(
          produce(draft => {
            draft.isMoreUsersListLoading = true;
          }),
        );
      } else {
        setVirtualizedSearchableSelectInputState(
          produce(draft => {
            draft.isUsersListLoading = true;
            draft.offset = 0;
            draft.totalCount = 0;
          }),
        );
      }

      const newOffset = fromBeginning ? 0 : offset + limit;

      if (!fromBeginning && newOffset >= totalCount) {
        return;
      }

      const payLoadData: GetAllUsersFromOurSystemQueryParam = {
        isActive: true,
        format: GetAllUsersFromOurSystemQueryParamFormatEnum.BASIC,
        limit: limit,
        offset: newOffset,
      };

      if (searchInputText.length > 0) {
        payLoadData.searchKey = AdminConstants.USERS.NAME;
        payLoadData.searchValue = searchInputText;
      }

      const userListRes = await fetchAllUsersFromOurSystem(payLoadData);

      const newUsersList = fromBeginning
        ? userListRes?.data?.rows ?? []
        : [...usersList, ...(userListRes?.data?.rows ?? [])];

      const addUnassignedOption =
        value?.name?.toLowerCase() !== UnassignedObj.name.toLowerCase() &&
        value?.name?.toLowerCase() !== UnassignObj.name.toLowerCase() &&
        showUnassignOption;

      if (addUnassignedOption && fromBeginning) {
        newUsersList.unshift(UnassignObj);
      }

      setVirtualizedSearchableSelectInputState(
        produce(draft => {
          draft.usersList = newUsersList;
          draft.totalCount = userListRes.data.count;
          draft.offset = newOffset;
        }),
      );

      setTimeout(() => {
        restoreScroll();
      }, 0);
    } catch (e) {
      const errMessage = getErrorMessage(e);
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
    } finally {
      if (!fromBeginning) {
        setVirtualizedSearchableSelectInputState(
          produce(draft => {
            draft.isMoreUsersListLoading = false;
          }),
        );
      } else {
        setVirtualizedSearchableSelectInputState(
          produce(draft => {
            draft.isUsersListLoading = false;
          }),
        );
      }
    }
  };

  // API region ends

  useEffect(() => {
    openDropdown &&
    shouldFetchUsersOptionsListByTaskId &&
    reassignUsersListByTaskId.length === 0
      ? fetchReAssignUsersListByTaskId(true)
      : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDropdown]);

  useEffect(() => {
    if (reassignUsersListByTaskId.length > 0) {
      getProfilePicsOfReassignUsers(reassignUsersListByTaskId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reassignUsersListByTaskId]);

  useEffect(() => {
    if (shouldLoadProfilePicsForBulkReAssign && options.length > 0) {
      getProfilePicsOfReassignUsers(options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldLoadProfilePicsForBulkReAssign, options]);

  const init = async () => {
    if (!shouldFetchUsersOptionsListByTaskId) {
      fetchData(true);
    }
    if (
      shouldLoadDefaultProfilePic &&
      defaultValue?.id &&
      defaultValue?.profilePicId &&
      !fetchedProfilePicRef.current[defaultValue.id]
    ) {
      const reduxProfilePicData =
        profilePicsUrlsWithLoaderFlagById[defaultValue.id];

      // Check if profile pic already exists in Redux
      const shouldFetchProfilePic =
        !reduxProfilePicData?.profilePicUrl?.length &&
        !reduxProfilePicData?.isProfilePicLoading;

      if (shouldFetchProfilePic) {
        // Temporarily store loading state in local cache
        profilePicsCacheRef.current[defaultValue.id] = {
          profilePicUrl: '',
          isProfilePicLoading: true,
        };

        setFetchProfilePicRef(defaultValue.id);
        if (
          !profilePicsUrlsWithLoaderFlagById[defaultValue.id]
            ?.isProfilePicLoading
        )
          await fetchProfilePicById(
            defaultValue.id,
            defaultValue.profilePicId,
            true,
          );
      }
    }
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (value === null && inputRef.current) {
      inputRef.current.focus();
    }

    if (!openDropdown && value === null) {
      isDefaultOptionRequired
        ? setValue(defaultValue)
        : setValue(defaultEmptyValue);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, openDropdown, defaultValue, isDefaultOptionRequired]);

  const handleOpenClosePopOver = (isModalOpen: boolean) => {
    if (!isModalOpen && Object.keys(pendingDispatchRef.current).length > 0) {
      moveProfilePicsFromPendingCacheIntoRedux();
    }
    handlePopOverOpenClose && handlePopOverOpenClose(isModalOpen);
  };

  const handleChange = (newValue: any) => {
    if (newValue?.name?.toLowerCase() === UnassignObj.name.toLowerCase()) {
      setValue(UnassignedObj);
    } else {
      setValue(newValue);
    }
    handleOnChange?.(newValue);
    handleOptionSelectChange?.(newValue);
  };

  const handleSearchUsersFromOurSystem = useDebouncedCallback(() => {
    shouldFetchUsersOptionsListByTaskId
      ? fetchReAssignUsersListByTaskId(true)
      : fetchData(true);
  }, SEARCH_DEBOUNCE_DELAY_IN_MS);

  const handleSearchUsersByInputText = useDebouncedCallback(
    (inputValue: string) => {
      setVirtualizedSearchableSelectInputState(
        produce(draft => {
          draft.searchInputText = inputValue;
          draft.usersList = [];
          draft.totalCount = 0;
        }),
      );
      handleSearchUsersFromOurSystem();
    },
    SEARCH_DEBOUNCE_DELAY_IN_MS,
  );

  const handleEndReached = useCallback(() => {
    if (isMoreUsersListLoading) {
      return;
    }

    if (virtuosoRef.current) {
      virtuosoRef.current.getState(state => {
        scrollPositionRef.current = state.scrollTop;
      });
    }

    if (shouldFetchUsersOptionsListByTaskId) {
      if (reassignUsersListByTaskId.length < totalCount) {
        fetchReAssignUsersListByTaskId(false);
      }
    } else if (usersList.length < totalCount) {
      fetchData(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isMoreUsersListLoading,
    shouldFetchUsersOptionsListByTaskId,
    reassignUsersListByTaskId?.length,
    usersList?.length,
    totalCount,
  ]);

  // render functions

  const renderCustomInput = ({ ...params }: any) => {
    const selectedUserFromOptions = shouldFetchUsersOptionsListByTaskId
      ? value
      : options.find(
          (option: { id: string | number | null }) => option.id === value?.id,
        );

    const isProfilePicIdAvailable =
      shouldFetchUsersOptionsListByTaskId &&
      selectedUserFromOptions?.profilePicId
        ? profilePicsUrlsWithLoaderFlagById[selectedUserFromOptions?.id ?? '']
            ?.isProfilePicLoading
        : false;
    const profilePic = selectedUserFromOptions
      ? profilePicsUrlsWithLoaderFlagById[selectedUserFromOptions.id]
          ?.profilePicUrl
      : '';

    const customOnChange = (event: ChangeEvent<HTMLInputElement>) => {
      const inputValue = event.target.value.replace(/^\s+/, '');
      if (inputValue?.length === 0 || inputValue?.length > 2) {
        handleSearchUsersByInputText(inputValue);
      }
    };

    return (
      <TextFieldWrapperStyled
        {...params}
        onChange={customOnChange}
        inputRef={inputRef}
        value={searchInputText ? searchInputText : value ?? ''}
        InputProps={{
          ...params.InputProps,
          startAdornment:
            showProfilePic && profilePic ? (
              <Avatar
                src={profilePic}
                alt={value ? value.name : ''}
                sx={{ width: 25, height: 25 }}
              />
            ) : isProfilePicIdAvailable ? (
              <LoadingGridForVirtualizedSearchableSelectInput>
                <Loader size={10} />
              </LoadingGridForVirtualizedSearchableSelectInput>
            ) : (
              <Avatar
                src={profilePic}
                alt={value ? value.name : ''}
                sx={{ width: 25, height: 25 }}
              />
            ),
        }}
      />
    );
  };

  const renderCustomPopper = (popperProps: any) => (
    <Popper
      {...popperProps}
      disablePortal={false}
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [0, 0],
          },
        },
      ]}
    />
  );

  const renderOptionItem = (
    rest: React.HTMLAttributes<HTMLLIElement>,
    option: any,
  ) => {
    const isProfilePicIdAvailable = !!option?.profilePicId;

    // Fetch profile pic from Redux store
    const reduxProfilePicData = profilePicsUrlsWithLoaderFlagById[option.id];

    // Check if we should use Redux profile pic (exists and not loading)
    const shouldUseReduxProfilePic =
      reduxProfilePicData?.profilePicUrl?.length > 0 &&
      !reduxProfilePicData.isProfilePicLoading;

    // If Redux data is valid, use it; otherwise, fallback to local ref
    const profilePicData = shouldUseReduxProfilePic
      ? reduxProfilePicData
      : profilePicsCacheRef.current[option.id] || {
          profilePicUrl: '',
          isProfilePicLoading: false,
        };

    // Determine if profile pic is loading based on conditions
    const isProfilePicLoading =
      isProfilePicIdAvailable &&
      (shouldFetchUsersOptionsListByTaskId ||
        shouldLoadProfilePicsForBulkReAssign)
        ? profilePicData.isProfilePicLoading
        : false;

    // Determine profile picture URL
    const profilePic = isProfilePicIdAvailable
      ? profilePicData.profilePicUrl
      : '';

    return (
      <Box component="li" {...rest} key={option.id}>
        {showProfilePic && profilePic ? (
          <Avatar src={profilePic} alt={option.name} sx={{ marginRight: 1 }} />
        ) : isProfilePicLoading ? (
          <LoadingGridForDropdownSelectInput>
            <Loader size={20} />
          </LoadingGridForDropdownSelectInput>
        ) : (
          <Avatar src={profilePic} alt={option.name} sx={{ marginRight: 1 }} />
        )}
        {option.name}
      </Box>
    );
  };

  const renderVirtualizedListBoxComponent = memo(
    forwardRef<HTMLDivElement, any>((listBoxProps, ref) => {
      const { children, ...rest } = listBoxProps;
      const items = React.Children.toArray(children) as React.ReactElement[];

      // Store `rest` for each option (needed for clickability)
      const itemRestMap = new Map();
      items.forEach((child: any, index) => {
        if (child.props) {
          itemRestMap.set(index, child.props); // Save the `rest` props for each item
        }
      });
      const ITEM_HEIGHT = 68;

      const calculatedHeight = Math.min(items.length * ITEM_HEIGHT, 400);
      const listHeight = items.length > 0 ? `${calculatedHeight}px` : 'auto';

      return (
        <Box
          {...rest}
          ref={ref}
          sx={{
            maxHeight: 400,
            overflow: 'auto',
            height: listHeight,
          }}
        >
          <Virtuoso
            {...listBoxProps}
            ref={virtuosoRef}
            data={options}
            followOutput="auto"
            style={{ maxHeight: 400 }}
            overscan={5}
            endReached={handleEndReached}
            itemContent={(index, option) => {
              if (isRestoringScrollRef.current) {
                return <SkeletonItem />;
              }
              const itemProps = itemRestMap.get(index) || {}; // Retrieve the correct `rest` props
              return renderOptionItem(itemProps, option);
            }}
            components={{
              Footer: () =>
                isMoreUsersListLoading ? (
                  <LoaderContainer>
                    <Loader size={30} />
                  </LoaderContainer>
                ) : null,
            }}
          />
        </Box>
      );
    }),
  );

  return (
    <Autocomplete
      {...(openDropdown != undefined ? { open: openDropdown } : {})}
      size={size}
      sx={{ width: width ?? '18.75rem' }}
      options={options}
      filterOptions={x => x}
      disabled={disabled}
      getOptionLabel={option => `${option.name} `}
      clearIcon={clearIcon}
      loading={
        shouldFetchUsersOptionsListByTaskId
          ? isReassignUsersListByTaskIdLoading
          : isUsersListLoading
          ? true
          : false
      }
      defaultValue={defaultOption?.name || value}
      value={value}
      onChange={(event, newValue) => {
        handleChange(newValue);
      }}
      renderInput={params => renderCustomInput({ ...params })}
      onOpen={() => {
        handleOpenClosePopOver(true);
      }}
      onClose={() => {
        handleOpenClosePopOver(false);
      }}
      isOptionEqualToValue={(option, val) => option?.id === val?.id}
      PopperComponent={renderCustomPopper}
      disableListWrap
      ListboxComponent={renderVirtualizedListBoxComponent}
    />
  );
};

export default memo(VirtualizedSearchableSelectInput);
