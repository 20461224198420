import { Box, Skeleton } from '@mui/material';
import { ReactElement } from 'react';

export const SkeletonItem = (): ReactElement => {
  return (
    <Box
      component="li"
      display="flex"
      alignItems="center"
      height={52}
      paddingX={2}
    >
      {/* Skeleton for Avatar */}
      <Skeleton
        variant="circular"
        width={42}
        height={42}
        sx={{ marginRight: 2, flexShrink: 0 }}
      />

      {/* Skeleton for Text */}
      <Skeleton variant="rectangular" height={15} sx={{ flex: 1 }} />
    </Box>
  );
};
