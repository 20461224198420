import TextButton from '@components/buttons/text-button/TextButton';
import styled from '@emotion/styled';
import { Grid, Typography } from '@mui/material';
import { APP } from 'src/styles/variables';

export const MainContainer = styled('div')`
  margin: 0.4rem;
`;

export const TopBarGrid = styled(Grid)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.3rem 0.937rem 0 0.937rem;
`;

export const TopBarTypography = styled(Typography)`
  font-size: ${APP.FONT.FONT_SIZE.H5};
  font-weight: ${APP.FONT.FONT_WEIGHT.CAPTION};
`;

export const DialogMainContainer = styled('div')`
  width: 30rem;
  padding: 0.3rem 0 0.937rem 0.937rem;
  border: 1px solid + ${APP.PALETTE.GREY.SHADE_300};
  height: fit-content;
`;

export const DialogSubContainer = styled('div')`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  color: ${APP.PALETTE.COMMON.BLACK};
  max-height: 400px;
  overflow: auto;
  word-wrap: break-word;
`;

export const LabelText = styled('span')`
  font-size: ${APP.FONT.FONT_SIZE.SUBTITLE1};
  font-weight: ${APP.FONT.FONT_WEIGHT.FW_600};
`;
export const LabelValue = styled('span')`
  font-size: ${APP.FONT.FONT_SIZE.SUBTITLE1};
  color: ${APP.PALETTE.COMMON.BLACK};
`;

export const ActionButtonWrapperGrid = styled(Grid)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0.2rem;
  padding-bottom: 0.5rem;
`;

export const OkTextButton = styled(TextButton)`
  width: 30%;
  font-size: ${APP.FONT.FONT_SIZE.SUBTITLE1};
  border-radius: 0.625rem;
  background-color: ${APP.PALETTE.SECONDARY.BLUE_LIGHT};
  border-color: ${APP.PALETTE.SECONDARY.BLUE_LIGHT};
  color: ${APP.PALETTE.COMMON.WHITE};
  &:hover {
    border-radius: 0.625rem;
    color: ${APP.PALETTE.COMMON.WHITE};
    background-color: ${APP.PALETTE.SECONDARY.BLUE_LIGHT};
  }
`;
