import styled from '@emotion/styled';
import { Button } from '@mui/material';
import { APP } from 'src/styles/variables';

type ButtonStyledType = {
  disabled?: boolean;
};

export const RowStyleDiv = styled('div')`
  font-size: ${APP.FONT.FONT_SIZE.BODY3};
`;

export const StatusStyledDiv = styled('div')`
  font-size: ${APP.FONT.FONT_SIZE.BODY3};
  text-transform: capitalize;
`;

export const MailBoxNameStyledDiv = styled('div')`
  font-size: ${APP.FONT.FONT_SIZE.BODY3};
  font-weight: ${APP.FONT.FONT_WEIGHT.BUTTON};
  overflow-wrap: anywhere;
`;

export const TypeCellSubTextStyle = styled('p')`
  font-size: ${APP.FONT.FONT_SIZE.FONT_SIZE_12PX};
  font-weight: ${APP.FONT.FONT_WEIGHT.BUTTON};
  color: ${APP.PALETTE.GREY.TERTIARY_COLOR};
  margin-top: 0.5rem;
`;

export const ButtonStyled = styled(Button)<ButtonStyledType>`
  width: 5.866rem;
  height: 2.5rem;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid ${APP.PALETTE.GREY.SEMI_GRAY};
  background-color: ${APP.PALETTE.COMMON.WHITE};
  color: ${APP.PALETTE.COMMON.BLACK};
  text-align: center;
  font-size: ${APP.FONT.FONT_SIZE.FONT_SIZE_12PX};
  font-weight: ${APP.FONT.FONT_WEIGHT.BUTTON};
  text-transform: uppercase;
  cursor: ${({ disabled = false }) => (disabled ? 'not-allowed' : 'pointer')};
`;

export const SubscribeButtonStyled = styled(ButtonStyled)`
  &.Mui-disabled {
    pointer-events: auto;
    cursor: ${({ disabled = false }) => (disabled ? 'not-allowed' : 'pointer')};
  }
`;

export const SubscribingButtonStyled = styled(ButtonStyled)`
  width: 6.8rem;
  &.Mui-disabled {
    pointer-events: auto;
    cursor: ${({ disabled = false }) => (disabled ? 'not-allowed' : 'pointer')};
  }
`;

export const StatusContainer = styled('div')`
  display: flex;
  gap: 3px;
`;

export const StatusWithImgStyledDiv = styled('div')`
  display: flex;
`;

export const ImgStyledDiv = styled('div')``;

export const SubscriptionStatusReasonContainer = styled('div')``;

export const ShowMoreLink = styled('span')`
  color: ${APP.FONT.FONT_COLOR.SECONDARY};
  font-weight: ${APP.FONT.FONT_WEIGHT.H1};
  cursor: pointer;
  text-decoration: underline;
`;
