import { Dialog } from '@mui/material';
import {
  ActionButtonWrapperGrid,
  OkTextButton,
  DialogSubContainer,
  DialogMainContainer,
  LabelValue,
  TopBarGrid,
  TopBarTypography,
  MainContainer,
} from './LargeReasonDialogStyle';
import IconButton from '@components/buttons/icon-button/IconButton';
import { t } from 'i18next';
import CloseIcon from '@assets/images/X.svg';

interface IQueueFlagDetailsDialogProps {
  isDialogOpen: boolean;
  onClose: () => void;
  subscriptionStatusReason: string;
}

const QueueFlagDetailsDialog = (
  props: IQueueFlagDetailsDialogProps,
): JSX.Element => {
  const { isDialogOpen, onClose, subscriptionStatusReason } = props;

  const renderTopBar = () => (
    <TopBarGrid>
      <TopBarTypography>
        {t(
          'ADMIN.SETTINGS_TAB.MONITORING_TAB.EMAILS_MONITORING.SUBSCRIPTION_STATUS_REASON',
        )}
      </TopBarTypography>
      <IconButton onClick={onClose}>
        <img src={CloseIcon} height={15} width={15} alt="..." />
      </IconButton>
    </TopBarGrid>
  );

  const renderFlagDetailsDialogContent = () => (
    <DialogMainContainer>
      <DialogSubContainer>
        {/* <LabelText>
          {t(
            'ADMIN.SETTINGS_TAB.MONITORING_TAB.EMAILS_MONITORING.SUBSCRIPTION_STATUS_REASON',
          )}
        </LabelText>
        <LabelText>{' : '}</LabelText> */}
        <LabelValue>
          {subscriptionStatusReason?.length > 0
            ? subscriptionStatusReason
            : '-'}
        </LabelValue>
      </DialogSubContainer>
    </DialogMainContainer>
  );

  const renderActionButton = () => (
    <ActionButtonWrapperGrid>
      <OkTextButton onClick={onClose}>{t('APP.OK')}</OkTextButton>
    </ActionButtonWrapperGrid>
  );

  const renderFlagDetailsDialog = () => (
    <Dialog open={isDialogOpen}>
      <MainContainer>
        {renderTopBar()}
        {renderFlagDetailsDialogContent()}
        {renderActionButton()}
      </MainContainer>
    </Dialog>
  );

  return renderFlagDetailsDialog();
};

export default QueueFlagDetailsDialog;
