import FormDialog from '@components/dialog/form-dialog/FormDialog';
import { useForm } from 'react-hook-form';
import { t } from 'i18next';
import { ErrorTextStyled } from 'src/styles/common/CommonStyles';
import { DividerStyled } from '../../queue-assignments/QueueAssignmentsStyle';
import {
  AssignedUser,
  UpdateAssignedUserPayloadDto,
} from 'src/services/service-handlers/private/queue-handlers/QueueServiceMapper';
import { useRef, useState } from 'react';
import { getErrorMessage } from '@utils/ErrorUtils';
import { enqueueSnackbar } from 'notistack';
import { produce } from 'immer';
import SnackBarConfig from '@components/snackbar/SnackbarConfig';
import { LoaderContainer } from './BulkReAssignUserDialogStyle';
import Loader from '@components/loaders/Loader';
import { updateAssignedUser } from 'src/services/service-handlers/private/queue-handlers/QueuePrivateService';
import { ProfilePicRefType } from 'src/types/Types';
import { AdminConstants } from '@constants/admin-constants/AdminConstants';
import VirtualizedSearchableSelectInput from '@components/select-input/select-text-input/virtualized-searchable-select-input/VirtualizedSearchableSelectInput';
import { VirtuosoHandle } from 'react-virtuoso';

interface IBulkReAssignUserDialogProps {
  handleCancel: () => void;
  isDialogOpen: boolean;
  BulkSelectedTaskIds: string[];
  refreshScreen?: (value: boolean) => void;
  resetSelectedRows: () => void;
  fetchedProfilePicRef: React.MutableRefObject<ProfilePicRefType>;
  setFetchProfilePicRef: (userId: string) => void;
}

interface IBulkReAssignUserState {
  isReAssignUserModalOpen: boolean;
  isUsersListLoading: boolean;
  isBulkUpdateProcessing: boolean;
  selectedUser: AssignedUser;
  searchInputText: string;
  lastSearchText: string;
  isSearchInputTextValueInsertedForInput: boolean;
}

const initialBulkReAssignUserState: IBulkReAssignUserState = {
  isReAssignUserModalOpen: false,
  isUsersListLoading: false,
  isBulkUpdateProcessing: false,
  searchInputText: '',
  lastSearchText: '',
  selectedUser: {
    contactEmail: null,
    email: '',
    id: '',
    name: '',
    profilePicId: null,
  },
  isSearchInputTextValueInsertedForInput: false,
};

const BulkReAssignUserDialog = (
  props: IBulkReAssignUserDialogProps,
): JSX.Element => {
  const {
    reset,
    formState: { errors },
  } = useForm({});
  const {
    isDialogOpen: openDialog,
    handleCancel,
    BulkSelectedTaskIds,
    refreshScreen,
    resetSelectedRows,
    fetchedProfilePicRef,
    setFetchProfilePicRef,
  } = props;

  const virtuosoRef = useRef<VirtuosoHandle | null>(null);
  const scrollPositionRef = useRef<number>(0);

  const [bulkReAssignUserState, setBulkReAssignUserState] =
    useState<IBulkReAssignUserState>(initialBulkReAssignUserState);

  const onClose = () => {
    if (!bulkReAssignUserState.isBulkUpdateProcessing) {
      reset();
      handleCancel();
    }
  };

  const onSubmitBulkReAssignUser = async () => {
    setBulkReAssignUserState(
      produce(draft => {
        draft.isBulkUpdateProcessing = true;
      }),
    );
    try {
      const isTaskUnassigned =
        bulkReAssignUserState.selectedUser?.name?.toLowerCase() ===
        AdminConstants.QUEUE.UN_ASSIGN.toLowerCase();

      const payload: UpdateAssignedUserPayloadDto = {
        ids: BulkSelectedTaskIds,
        userId: bulkReAssignUserState.selectedUser?.id,
        ...(isTaskUnassigned && { isUnassigned: isTaskUnassigned }),
      };

      const updateBulkReAssignUserForSelectedTasksApiResponse =
        await updateAssignedUser(payload);
      const successMessage =
        updateBulkReAssignUserForSelectedTasksApiResponse.data.message;
      enqueueSnackbar(successMessage, SnackBarConfig.getSuccess());
      setBulkReAssignUserState(
        produce(draft => {
          draft.isBulkUpdateProcessing = false;
        }),
      );
      resetSelectedRows();
      refreshScreen?.(true);
      onClose();
    } catch (errorResponse) {
      const errorMessage = getErrorMessage(errorResponse);
      enqueueSnackbar(errorMessage, SnackBarConfig.getError());
      setBulkReAssignUserState(
        produce(draft => {
          draft.isBulkUpdateProcessing = false;
        }),
      );
    }
  };

  const handlePopOverOpenClose = (isModalOpen = false) => {
    setBulkReAssignUserState(
      produce(draft => {
        draft.isReAssignUserModalOpen = isModalOpen;
      }),
    );
  };

  const handleOptionSelectChange = (newValue: AssignedUser) => {
    setBulkReAssignUserState(
      produce(draft => {
        draft.selectedUser = newValue;
      }),
    );
  };

  const renderSearchableSelectInput = () => {
    return (
      <div>
        <VirtualizedSearchableSelectInput
          width=" 24.5rem"
          handlePopOverOpenClose={(isModalOpen?: boolean) => {
            handlePopOverOpenClose(isModalOpen);
          }}
          openDropdown={bulkReAssignUserState.isReAssignUserModalOpen}
          isDefaultOptionRequired={false}
          handleOptionSelectChange={handleOptionSelectChange}
          shouldLoadProfilePicsForBulkReAssign={true}
          fetchedProfilePicRef={fetchedProfilePicRef}
          setFetchProfilePicRef={setFetchProfilePicRef}
          virtuosoRef={virtuosoRef}
          scrollPositionRef={scrollPositionRef}
        />
      </div>
    );
  };

  const renderFormDialog = () => (
    <FormDialog
      width="27.5rem" // 440 px
      isDialogOpen={openDialog}
      handleCancel={onClose}
      isSaveInProgress={bulkReAssignUserState.isBulkUpdateProcessing}
      titleText={t(
        'ADMIN.QUEUE_TABS.BULK_ACTION_DIALOG.BULK_RE_ASSIGN_USER_DIALOG.TITLE',
      )}
      subTitleText={t(
        'ADMIN.QUEUE_TABS.BULK_ACTION_DIALOG.BULK_RE_ASSIGN_USER_DIALOG.SUBTITLE',
      )}
      dialogContent={
        <div>
          {bulkReAssignUserState.isUsersListLoading ? (
            <LoaderContainer>
              <Loader size={40} />
            </LoaderContainer>
          ) : (
            renderSearchableSelectInput()
          )}

          {errors.changeQueueItem && (
            <ErrorTextStyled>
              {t(`${errors.changeQueueItem.message}`)}
            </ErrorTextStyled>
          )}
          <DividerStyled style={{ marginTop: '2.5rem' }} />
        </div>
      }
      submitButtonText={t(
        'ADMIN.QUEUE_TABS.BULK_ACTION_DIALOG.BULK_RE_ASSIGN_USER_DIALOG.SAVE_AND_UPDATE',
      )}
      submitButtonLoadingText={t(
        'ADMIN.QUEUE_TABS.BULK_ACTION_DIALOG.BULK_RE_ASSIGN_USER_DIALOG.SAVING',
      )}
      cancelButtonText={t(
        'ADMIN.QUEUE_TABS.BULK_ACTION_DIALOG.BULK_RE_ASSIGN_USER_DIALOG.CANCEL',
      )}
      disableSubmitButton={
        bulkReAssignUserState.isUsersListLoading ||
        bulkReAssignUserState.isBulkUpdateProcessing ||
        (!bulkReAssignUserState.selectedUser?.id &&
          bulkReAssignUserState.selectedUser?.name?.toLowerCase() !==
            AdminConstants.QUEUE.UN_ASSIGN.toLowerCase())
      }
      onSubmit={onSubmitBulkReAssignUser}
    />
  );

  return renderFormDialog();
};

export default BulkReAssignUserDialog;
