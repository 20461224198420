import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  DefaultAssigneeForFilterPayloadType,
  ParentQueueTabPathNamePayloadType,
  QueueStateType,
  ReloadQueueTableGridPayloadType,
  SearchAndFilterConfigurationPayloadType,
  SearchByCaseIdPayloadType,
  SetIsRefetchingNeededPayloadType,
  SetProfilePicsUrlsPayloadType,
  SetProfilePicsUrlsWithLoaderFlagByIdPayloadType,
  SetQueueSearchAndFilterPayloadType,
  SetRefreshTaskCountSummaryForCategoryPayloadType,
  ShowBulkActionButtonPayloadType,
  ShowBulkChangeTypeDialogPayloadType,
  ShowBulkReAssignDialogPayloadType,
  isTaskCompletedPayloadType,
} from './QueueTypes';
import { generateRandomTableName } from '@utils/miscellaneousUtils';
import { AdminConstants } from '@constants/admin-constants/AdminConstants';

const initialState: QueueStateType = {
  searchByCaseId: '',
  showBulkActionMenuButton: false,
  reloadQueueTableGrid: false,
  fetchDataFnQueryKeyName: AdminConstants.QUEUE.QUEUE_TABLE.QUEUE_TABLE_NAME,
  fetchDataFnQueryKeyNameForAssignmentsByUsers:
    AdminConstants.QUEUE.QUEUE_ASSIGNMENTS.USERS_TABLE_FN_NAME,
  showBulkReAssignDialog: false,
  showBulkChangeTypeDialog: false,
  isTaskCompleted: false,
  queueSearchAndFilterOptions: {},
  searchAndFilterConfiguration: {
    SEARCH_BY_OPTIONS: [],
    FILTER_OPTIONS: [],
  },
  defaultAssigneeForFilter: {
    id: '-1',
    profilePicId: null,
    name: '',
    email: null,
    contactEmail: null,
  },
  isRefetchingNeeded: false,
  profilePicsUrls: {},
  refreshTaskCountSummaryForCategory: false,
  profilePicsUrlsWithLoaderFlagById: {},
  updatedStatusesForAssigneeChange: {},
  parentQueueTabPathName: '',
  queueRowStatusData: [],
  shouldFilterActionButtonsDisable: false,
  isFilterResetButtonClicked: false,
};

const QueueSlice = createSlice({
  initialState,
  name: 'queue',
  reducers: {
    searchByCaseId(state, action: PayloadAction<SearchByCaseIdPayloadType>) {
      state.searchByCaseId = action.payload.searchByCaseId;
    },
    showBulkActionButton(
      state,
      action: PayloadAction<ShowBulkActionButtonPayloadType>,
    ) {
      state.showBulkActionMenuButton = action.payload.showBulkActionMenuButton;
    },
    showBulkReAssignDialogPopUp(
      state,
      action: PayloadAction<ShowBulkReAssignDialogPayloadType>,
    ) {
      state.showBulkReAssignDialog = action.payload.showBulkReAssignDialog;
    },
    showBulkChangeTypePopUp(
      state,
      action: PayloadAction<ShowBulkChangeTypeDialogPayloadType>,
    ) {
      state.showBulkChangeTypeDialog = action.payload.showBulkChangeTypeDialog;
    },
    reloadQueueTable(
      state,
      action: PayloadAction<ReloadQueueTableGridPayloadType>,
    ) {
      state.reloadQueueTableGrid = action.payload.reloadQueueTableGrid;
    },
    changeTableName(state) {
      state.fetchDataFnQueryKeyName = generateRandomTableName(
        AdminConstants.QUEUE.QUEUE_TABLE.QUEUE_TABLE_NAME,
      );
      state.reloadQueueTableGrid = false;
    },
    resetQueueState(state) {
      const { profilePicsUrlsWithLoaderFlagById } = state;
      return {
        ...initialState,
        profilePicsUrlsWithLoaderFlagById,
      };
    },
    taskCompleted(state, action: PayloadAction<isTaskCompletedPayloadType>) {
      state.isTaskCompleted = action.payload.isTaskCompleted;
    },
    setSearchAndFilterConfiguration(
      state,
      action: PayloadAction<SearchAndFilterConfigurationPayloadType>,
    ) {
      state.searchAndFilterConfiguration = action.payload;
    },
    setDefaultAssigneeForFilter(
      state,
      action: PayloadAction<DefaultAssigneeForFilterPayloadType>,
    ) {
      state.defaultAssigneeForFilter = action.payload;
    },
    setIsRefetchingNeeded(
      state,
      action: PayloadAction<SetIsRefetchingNeededPayloadType>,
    ) {
      state.isRefetchingNeeded = action.payload.data;
    },
    setQueueSearchAndFilterOptions(
      state,
      action: PayloadAction<SetQueueSearchAndFilterPayloadType>,
    ) {
      state.queueSearchAndFilterOptions = action?.payload?.data;
    },
    setProfilePicsUrls(
      state,
      action: PayloadAction<SetProfilePicsUrlsPayloadType>,
    ) {
      const { userId, profilePicUrl } = action.payload;
      state.profilePicsUrls[userId] = profilePicUrl;
    },
    changeAssignmentByUserTableName(state) {
      state.fetchDataFnQueryKeyName = generateRandomTableName(
        AdminConstants.QUEUE.QUEUE_TABLE.QUEUE_TABLE_NAME,
      );
      state.fetchDataFnQueryKeyNameForAssignmentsByUsers =
        generateRandomTableName(
          AdminConstants.QUEUE.QUEUE_ASSIGNMENTS.USERS_TABLE_FN_NAME,
        );
      state.reloadQueueTableGrid = false;
    },

    setRefreshTaskCountSummaryForCategory(
      state,
      action: PayloadAction<SetRefreshTaskCountSummaryForCategoryPayloadType>,
    ) {
      state.refreshTaskCountSummaryForCategory = action?.payload?.data;
    },

    setProfilePicsUrlsWithLoaderFlagById(
      state,
      action: PayloadAction<SetProfilePicsUrlsWithLoaderFlagByIdPayloadType>,
    ) {
      const { userId, profilePicUrl, isProfilePicLoading } = action.payload;
      if (!state.profilePicsUrlsWithLoaderFlagById[userId]) {
        state.profilePicsUrlsWithLoaderFlagById[userId] = {
          profilePicUrl: profilePicUrl,
          isProfilePicLoading: isProfilePicLoading,
        };
      } else {
        (state.profilePicsUrlsWithLoaderFlagById[userId].profilePicUrl =
          profilePicUrl),
          (state.profilePicsUrlsWithLoaderFlagById[userId].isProfilePicLoading =
            isProfilePicLoading);
      }
    },
    updateTaskStatusWhenAssigneeChanges(
      state,
      action: PayloadAction<{ rowId: string; status: string }>,
    ) {
      const { rowId, status } = action.payload;

      if (state.updatedStatusesForAssigneeChange) {
        state.updatedStatusesForAssigneeChange[rowId] = status;
      }
    },
    clearUpdatedTaskStatuses: state => {
      state.updatedStatusesForAssigneeChange = {};
    },
    setParentQueueTabPathName(
      state,
      action: PayloadAction<ParentQueueTabPathNamePayloadType>,
    ) {
      state.parentQueueTabPathName = action.payload.parentQueueTabPathNameUrl;
    },
    updateQueueRowStatusData(
      state,
      action: PayloadAction<{ rowId: string; status: string }>,
    ) {
      const { rowId, status } = action.payload;

      const existingRow = state.queueRowStatusData?.find(
        row => row.rowId === rowId,
      );

      if (existingRow) {
        existingRow.status = status;
      } else {
        state.queueRowStatusData?.push({ rowId, status });
      }
    },
    clearQueueRowStatusData(state, action: PayloadAction<{ rowId: string }>) {
      const { rowId } = action.payload;
      if (state.queueRowStatusData) {
        state.queueRowStatusData = state.queueRowStatusData.filter(
          row => row.rowId !== rowId,
        );
      }
    },
    setShouldFilterActionButtonsDisable(
      state,
      action: PayloadAction<{ shouldFilterActionButtonsDisable: boolean }>,
    ) {
      const { shouldFilterActionButtonsDisable } = action.payload;

      state.shouldFilterActionButtonsDisable = shouldFilterActionButtonsDisable;
    },
    setIsFilterResetButtonClicked(
      state,
      action: PayloadAction<{ isResetButtonClicked: boolean }>,
    ) {
      const { isResetButtonClicked } = action.payload;
      state.isFilterResetButtonClicked = isResetButtonClicked;
    },
  },
});

export const {
  searchByCaseId,
  showBulkActionButton,
  reloadQueueTable,
  changeTableName,
  showBulkReAssignDialogPopUp,
  showBulkChangeTypePopUp,
  resetQueueState,
  taskCompleted,
  setQueueSearchAndFilterOptions,
  setSearchAndFilterConfiguration,
  setDefaultAssigneeForFilter,
  setIsRefetchingNeeded,
  setProfilePicsUrls,
  changeAssignmentByUserTableName,
  setRefreshTaskCountSummaryForCategory,
  setProfilePicsUrlsWithLoaderFlagById,
  updateTaskStatusWhenAssigneeChanges,
  clearUpdatedTaskStatuses,
  setParentQueueTabPathName,
  updateQueueRowStatusData,
  clearQueueRowStatusData,
  setShouldFilterActionButtonsDisable,
  setIsFilterResetButtonClicked,
} = QueueSlice.actions;
export default QueueSlice.reducer;
