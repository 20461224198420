import styled from '@emotion/styled';
import { Button } from '@mui/material';
import { APP } from 'src/styles/variables';

export const RowStyleDiv = styled('div')`
  font-size: ${APP.FONT.FONT_SIZE.BODY3};
  font-weight: ${APP.FONT.FONT_WEIGHT.BUTTON};
`;

export const RowValue = styled('div')`
  font-size: ${APP.FONT.FONT_SIZE.BODY3};
`;

export const RowStatusValue = styled('div')`
  font-size: ${APP.FONT.FONT_SIZE.BODY3};
  text-transform: capitalize;
`;

export const OverlayLoaderContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${APP.PALETTE.BACKGROUND.WHITE_WITH_OPACITY};
  z-index: 10000;
`;

type ButtonStyledType = {
  disabled?: boolean;
};

export const ButtonStyled = styled(Button)<ButtonStyledType>`
  width: 5.866rem;
  height: 2.5rem;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid ${APP.PALETTE.GREY.SEMI_GRAY};
  background-color: ${APP.PALETTE.COMMON.WHITE};
  color: ${APP.PALETTE.COMMON.BLACK};
  text-align: center;
  font-size: ${APP.FONT.FONT_SIZE.FONT_SIZE_12PX};
  font-weight: ${APP.FONT.FONT_WEIGHT.BUTTON};
  text-transform: uppercase;
  cursor: ${({ disabled = false }) => (disabled ? 'not-allowed' : 'pointer')};
`;

export const DbQueryReprocessButtonStyled = styled(ButtonStyled)`
  &.Mui-disabled {
    pointer-events: auto;
    cursor: ${({ disabled = false }) => (disabled ? 'not-allowed' : 'pointer')};
  }
`;

export const TypeCellSubTextStyle = styled('p')`
  font-size: ${APP.FONT.FONT_SIZE.FONT_SIZE_12PX};
  font-weight: ${APP.FONT.FONT_WEIGHT.BUTTON};
  color: ${APP.PALETTE.GREY.TERTIARY_COLOR};
  margin-top: 0.5rem;
`;

export const ConnectionNameTextStyle = styled('p')`
  font-size: ${APP.FONT.FONT_SIZE.BODY3};
  font-weight: ${APP.FONT.FONT_WEIGHT.BUTTON};
  color: ${APP.PALETTE.GREY.TERTIARY_COLOR};
  margin-top: 0.5rem;
`;

export const LastRunTimeContainer = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const LastRunTimeStyledDiv = styled('div')`
  display: flex;
  font-size: ${APP.FONT.FONT_SIZE.FONT_SIZE_12PX};
  gap: 3px;
`;

export const LastRunStatusContainer = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const LastRunStatusStyledDiv = styled('div')`
  display: flex;
  gap: 3px;
`;
export const LastRunStatusWithImgStyledDiv = styled('div')`
  display: flex;
`;

export const ImgStyledDiv = styled('div')``;

export const LastSuccessfulTimeContainer = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const LastSuccessfulTimeStyledDiv = styled('div')`
  display: flex;
  gap: 3px;
`;
